import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"

export const useConsultationRatingModalStyles = makeStyles((theme: Theme) => createStyles({
  popup: {
    width: "600px",
    maxWidth: "100%",
    padding: `${theme.spacing(2)}px !important`,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-end",
  },
  text: {
    marginBottom: theme.spacing(2),
    whiteSpace: "break-spaces",
    color: theme.palette.text.secondary,
  },
  succesBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
    textAlign: "center",
  },
  closeButton: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-end",
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    justifyContent: "center",
    "& .MuiChip-root": {
      margin: 0,
      borderWidth: 2,
      transition: theme.transitions.create(["background-color", "color", "border-color"]),
      "&.error": {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        "&:hover": {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.common.white
        },
        "&.selected": {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
          "&:hover": {
            backgroundColor: theme.palette.error.dark
          }
        }
      },
      "&.success": {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
        "&:hover": {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.common.white
        },
        "&.selected": {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
          "&:hover": {
            backgroundColor: theme.palette.success.dark
          }
        }
      }
    }
  },
  selectedChip: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`
    }
  },
  titleWrapper: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(0.5)
  },
  messageWrapper: {
    padding: theme.spacing(2),
    textAlign: "center"
  }
}))
