import React, { FC, useEffect, useState } from "react"
import {useParams} from "react-router"
import {Box, Typography} from "@material-ui/core"
import {parseISO} from "date-fns"
import i18next from "i18next"

import {storedMagicLinkKey} from "../../app.config"
import {useAppSelector} from "../../hooks/storeHooks"
import useConsultationLang from "../../hooks/useConsultationLang"
import {selectUserId} from "../../store/session/session.selectors"
import DoctorIsWritingDotsAnimationDark
  from "../common/lottieAnimations/animations/DoctorIsWritingDotsAnimationDark.component"
import DoctorConsultationChatMessageItemComponent from "./consultationChatMessageItem/DoctorConsultationChatMessageItem.component"
import FileChatMessageItemComponent from "./consultationChatMessageItem/FileChatMessageItem.component"
import ImageChatMessageItemComponent from "./consultationChatMessageItem/ImageChatMessageItem.component"
import PatientConsultationChatMessageItemComponent from "./consultationChatMessageItem/PatientConsultationChatMessageItem.component"
import SystemConsultationChatMessageItemComponent from "./consultationChatMessageItem/SystemConsultationChatMessageItem.component"
import WelcomeChatMessageItemComponent from "./consultationChatMessageItem/WelcomeChatMessageItem.component"
import ConsultationChatTopBoxComponent from "./consultationChatTopBox/ConsultationChatTopBox.component"
import ConsultationRatingModal from "./consultationRatingModal/ConsultationRatingModal.component"
import ConsultationSurvey from "./consultationStartPopup/ConsultationSurvey.component"
import SendNewMessageForm from "./sendNewMessageForm/SendNewMessageForm.component"
import {scrollToBottom} from "../../utils/scrollTo"
import {isAfterConsultationStartDate} from "../consultation/Consultation.utils"
import {getDoctorAvatarPseudoRandomBoolean} from "../doctor/Doctor.utils"
import {ConsultationModel, ConsultationStatusNumber} from "../consultation/Consultation.types"
import {ChatMessageType, ConsultationChatItem, InstantChatDoctorDataType} from "./ConsultationChat.types"
import {useConsultationChatStyles} from "./ConsultationChat.styles"

import ConsultationWithDoctorQuestionPopup from "./consultationStartPopup/ConsultationWithDoctorQuestionPopup"

interface ConsultationChatProps {
  consultationData: ConsultationModel;
  chatMessages: ConsultationChatItem[];
  subtitleMessage?: ConsultationChatItem;
  isDoctorTyping: boolean;
  consultationClosedMessage: ConsultationChatItem | null;
  hasBeenAdditionalMessageSent: boolean;
  addNewPatientMessage: (newPatientMessage: ConsultationChatItem) => void;
  instantChatDoctorData?: InstantChatDoctorDataType;
}

const WITH_SURVEY_MODE = "survey-mode"
const SHOW_SURVEY_DELAY = 1000

const ConsultationChat: FC<ConsultationChatProps> = (
  {
    consultationData,
    chatMessages,
    subtitleMessage,
    isDoctorTyping,
    consultationClosedMessage,
    hasBeenAdditionalMessageSent,
    addNewPatientMessage,
    instantChatDoctorData,
  }) => {
  const consultationLang = useConsultationLang()
  const classes = useConsultationChatStyles()

  const patientId = useAppSelector(selectUserId)
  const doctorAvatarPseudorandomBoolean = getDoctorAvatarPseudoRandomBoolean(consultationData.specialization.name)
  const consultationReasonsSelected = chatMessages.filter(message => message.messageType === ChatMessageType.CONSULTATION_REASONS).length
  const {additionalParam} = useParams<{additionalParam: string}>()
  const magicLinkId = sessionStorage.getItem(storedMagicLinkKey)
  const showDocumentationInfo =
    consultationData.status_number !== ConsultationStatusNumber.STARTED
    && !isAfterConsultationStartDate(parseISO(consultationData.visit_at))
    && !chatMessages.filter(messageItem => messageItem.message === "chat.consultation_started").length
  const surveyModeOn = additionalParam === WITH_SURVEY_MODE && showDocumentationInfo && magicLinkId
  const [surveyMode, setSurveyMode] = useState(false)
  const [multiPrescriptionDoctorPopupRequired, setMultiPrescriptionDoctorPopupRequired] = useState(consultationData.isMultiPrescriptionMedicine === true && consultationData.multiPrescriptionPatientPopupAnswer === null)

  const doctorDetails = {
    doctorSpecialization: consultationData.specialization,
    doctorAvatarPseudorandomBoolean: doctorAvatarPseudorandomBoolean,
    doctorTitle: instantChatDoctorData?.doctorName ?? consultationData.doctorNameWithDegree,
    doctorImage: {
      src: instantChatDoctorData?.avatarUrl ?? consultationData.doctor.avatar,
      alt: ""
    }
  }

  useEffect(() => {
    scrollToBottom()

    if (surveyModeOn) {
      setTimeout(() => {
        setSurveyMode(true)
      }, SHOW_SURVEY_DELAY)
    }
  }, [])

  return (
    <Box className={classes.root}>
      <ConsultationChatTopBoxComponent
        consultationType={consultationData.consultationType}
        consultationStatus={consultationData.status_number}
        visitAt={consultationData.visit_at}
        subtitle={subtitleMessage}
        addNewPatientMessage={addNewPatientMessage}
        consultationId={consultationData.id}
        patientId={patientId}
        isStationaryVisit={!!consultationData?.stationaryVisitAddress}
        {...doctorDetails}
      />

      <Box className={classes.chat}>
        { chatMessages.map((messageItem, idx) => {
          const isPatientMessage = messageItem.sender === patientId
          const messageItemsProps = {
            ...messageItem,
            key: `${messageItem.id}-${messageItem.messageRaw}-${idx}`,
            messageRawType: messageItem.messageType
          }

          const messageRawType = messageItemsProps.messageRawType

          switch (messageRawType) {
            case (ChatMessageType.CONSULTATION_REASONS):
            case (ChatMessageType.OWN_NORMAL):
            case (ChatMessageType.USER):
              return isPatientMessage
                ? <PatientConsultationChatMessageItemComponent
                  isConsultationReasonMessage={messageRawType === ChatMessageType.CONSULTATION_REASONS}
                  {...messageItemsProps}
                />
                : <DoctorConsultationChatMessageItemComponent
                  {...messageItemsProps}
                  doctorAvatarPseudorandomBoolean={doctorAvatarPseudorandomBoolean}
                  doctorImage={{
                    src: consultationData.doctor.avatar,
                    alt: ""
                  }}
                />
            case (ChatMessageType.WELCOME):
            case (ChatMessageType.HELP):
              return <WelcomeChatMessageItemComponent
                {...messageItemsProps}
                {...doctorDetails}
                consultationType={consultationData.consultationType}
                messageRawType={messageRawType}
                sender={messageItem.sender}
                isStationaryVisit={!!consultationData?.stationaryVisitAddress}
              />
            case (ChatMessageType.SELECT_LANGUAGE):
            case (ChatMessageType.SYSTEM):
              return <SystemConsultationChatMessageItemComponent {...messageItemsProps}/>
            case (ChatMessageType.PHOTO):
              return <ImageChatMessageItemComponent
                {...messageItemsProps}
                isPatientMessage={isPatientMessage}
              />
            case (ChatMessageType.PRESCRIPTION):
            case (ChatMessageType.EPRESCRIPTION):
            case (ChatMessageType.REFERRAL):
            case (ChatMessageType.EREFERRAL):
            case (ChatMessageType.RECOMMENDATIONS_LINK):
            case (ChatMessageType.TRANS_PRESCRIPTION):
            case (ChatMessageType.EZLA):
            case (ChatMessageType.PDF):
            case (ChatMessageType.FILE):
              return isPatientMessage ? null : <FileChatMessageItemComponent language={consultationData?.language} {...messageItemsProps}/>
          }
        })}
      </Box>

      <Box className={classes.doctorIsWritingWrapper}>
        { isDoctorTyping &&
          <Box className={classes.doctorIsWritingBox}>
            <Typography  className={classes.doctorIsWritingText}>
              { consultationLang.getLabel("chatConsultation:doctorIsTyping") }
            </Typography>

            <DoctorIsWritingDotsAnimationDark/>
          </Box>
        }
      </Box>

      {
        (consultationReasonsSelected > 0 && showDocumentationInfo) && (
          <Box
            className={classes.documentationInfoWrapper}
          >
            <Box
              className={classes.documentationInfo}
            >
              {i18next.t("consultation:additionalInfo")}
            </Box>
          </Box>
        )
      }

      <SendNewMessageForm
        consultationId={consultationData.id}
        patientId={patientId}
        consultationClosedMessage={consultationClosedMessage}
        consultationFinishedAt={consultationData.finished_at}
        hasBeenAdditionalMessageSent={hasBeenAdditionalMessageSent}
        addNewPatientMessage={addNewPatientMessage}
        consultationStatus={consultationData.status_number}
        consultationType={consultationData.consultationType}
      />

      { !!consultationClosedMessage && (
        <ConsultationRatingModal
          consultationId={consultationData.id}
          doctorName={consultationData?.doctorName || ""}
          rate={consultationData.rate}
          hideCloseButtons={false}
        />
      )}
      {
        multiPrescriptionDoctorPopupRequired &&
          <ConsultationWithDoctorQuestionPopup
            open={multiPrescriptionDoctorPopupRequired}
            onClose={() => setMultiPrescriptionDoctorPopupRequired(false)}
            consultationId={consultationData.id}
          />
      }
      {
        !consultationReasonsSelected && !isAfterConsultationStartDate(parseISO(consultationData.visit_at)) && (
          <ConsultationSurvey
            open={surveyMode}
            onClose={() => setSurveyMode(false)}
            addNewPatientMessage={addNewPatientMessage}
            consultationId={consultationData.id}
            patientId={patientId}
            magicLinkId={magicLinkId}
          />
        )
      }
    </Box>
  )
}

export default ConsultationChat
