import React, { FC, useEffect } from "react"
import { Box, Chip, Typography } from "@material-ui/core"
import clsx from "clsx"

import useConsultationLang from "../../../hooks/useConsultationLang"
import { Satisfaction } from "./ConsultationRatingModal.types"
import { useConsultationRatingModalStyles } from "./ConsultationRatingModal.styles"

import { NEGATIVE_FEEDBACK_TAGS, POSITIVE_FEEDBACK_TAGS } from "./ConsultationRatingModal.constants"

interface FeedbackTagsProps {
  rating: Satisfaction | null
  selectedTags: string[]
  onTagsChange: (tags: string[]) => void
  error?: boolean
  helperText?: string
}

const FeedbackTags: FC<FeedbackTagsProps> = ({ rating, selectedTags, onTagsChange, error, helperText }) => {
  const classes = useConsultationRatingModalStyles()
  const consultationLang = useConsultationLang()

  const isNegativeRating = rating ? [Satisfaction.VERY_LOW, Satisfaction.LOW, Satisfaction.MEDIUM].includes(rating) : false
  const tags = isNegativeRating ? NEGATIVE_FEEDBACK_TAGS : POSITIVE_FEEDBACK_TAGS

  // Efekt czyszczący tagi przy zmianie typu oceny
  useEffect(() => {
    if (rating) {
      // Sprawdzamy, czy aktualnie zaznaczone tagi należą do aktualnego zestawu tagów
      const hasInvalidTags = selectedTags.some(tag => !tags.includes(tag))
      if (hasInvalidTags) {
        onTagsChange([]) // Czyścimy wszystkie tagi
      }
    }
  }, [isNegativeRating, rating, selectedTags, tags, onTagsChange])

  if (!rating) return null

  const question = isNegativeRating
    ? consultationLang.getLabel("chatConsultation:rating:whatCanWeImprove")
    : consultationLang.getLabel("chatConsultation:rating:whatDidYouLike")

  const handleTagClick = (tag: string) => {
    const currentTags = selectedTags ?? []
    const isSelected = currentTags.includes(tag)
    const newTags = isSelected
      ? currentTags.filter(t => t !== tag)
      : [...currentTags, tag]
    onTagsChange(newTags)
  }

  return (
    <div>
      {tags.length > 0 && (
        <>
          <div className={classes.tagsContainer}>
            <Typography variant="h5" style={{ width: "100%", textAlign: "center" }}>
              {question}
            </Typography>
          </div>
          <div className={classes.tagsContainer}>
            {tags.map((tag) => {
              const isSelected = selectedTags.includes(tag)
              return (
                <Chip
                  key={tag}
                  label={consultationLang.getLabel(tag)}
                  onClick={() => handleTagClick(tag)}
                  className={clsx(isNegativeRating ? "error" : "success", {
                    selected: isSelected
                  })}
                  variant="outlined"
                />
              )
            })}
          </div>
          {error && helperText && (
            <div style={{ marginTop: "8px" }}>
              <Typography color="error" variant="caption">
                {helperText}
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FeedbackTags
