export enum Satisfaction {
  VERY_LOW = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  VERY_HIGH = 5
}

export interface RateConsultationDataType {
  consultation_id: string;
  satisfaction: Satisfaction | null;
  message: string;
  selectedTags: string[];
}
