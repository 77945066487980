import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from "react"
import {Controller, useForm} from "react-hook-form"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"
import { RouteConfig } from "axios"

import api from "../../../api/api"
import {postConsultationChatRatingV2Config} from "../../../api/routes"
import useConsultationLang from "../../../hooks/useConsultationLang"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import CloseButton from "../../common/closeButton/CloseButton.component"
import Confetti from "../../common/confetti/Confetti.component"
import GlobalFormErrorMessage from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import FeedbackTags from "./FeedbackTags.component"
import { redirectToError500Page } from "../../../utils/handleErrors"
import {ConsultationModel} from "../../consultation/Consultation.types"
import { RateConsultationDataType, Satisfaction } from "./ConsultationRatingModal.types"
import { Path } from "react-hook-form/dist/types"
import {useConsultationRatingModalStyles} from "./ConsultationRatingModal.styles"

enum CancelModalReason {
  ESCAPE_KEY_DOWN = "escapeKeyDown",
  BACKDROP_CLICK = "backdropClick",
}

interface ConsultationRatingModalProps {
  consultationId: string;
  doctorName: string;
  rate: ConsultationModel["rate"];
  initialRate?: number | null;
  onRatingSuccess: () => void;
  title?: string;
  hideCloseButtons?: boolean;
  ratingRouteConfig?: RouteConfig;
}

const ConsultationRatingModal: FC<ConsultationRatingModalProps> = ({
  consultationId,
  doctorName,
  rate,
  initialRate,
  onRatingSuccess,
  title,
  hideCloseButtons = false,
  ratingRouteConfig = postConsultationChatRatingV2Config
}) => {
  const classes = useConsultationRatingModalStyles()

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const consultationLang = useConsultationLang()

  const getInitialSatisfaction = (rating: number | null) => {
    if (rating === null) return null
    const satisfactionMap: Record<number, Satisfaction> = {
      1: Satisfaction.VERY_LOW,
      2: Satisfaction.LOW,
      3: Satisfaction.MEDIUM,
      4: Satisfaction.HIGH,
      5: Satisfaction.VERY_HIGH
    }
    return satisfactionMap[rating] || null
  }

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isSuccessBox, setIsSuccessBox] = useState<boolean>(false)
  const [globalError, setGlobalError] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const form = useForm<RateConsultationDataType>({
    defaultValues: {
      consultation_id: consultationId,
      satisfaction: getInitialSatisfaction(initialRate),
      message: "",
      selectedTags: []
    },
    resolver: (values) => {
      const errors: Record<string, { type: string; message: string }> = {}
      return {
        values,
        errors
      }
    }
  })

  const handleSatisfactionChange = (onChange: (value: Satisfaction|null) => void) => (event: ChangeEvent<unknown>, newValue: number|null) => {
    if (newValue === null) {
      onChange(null)
      return
    }

    const satisfactionMap: Record<number, Satisfaction> = {
      1: Satisfaction.VERY_LOW,
      2: Satisfaction.LOW,
      3: Satisfaction.MEDIUM,
      4: Satisfaction.HIGH,
      5: Satisfaction.VERY_HIGH
    }

    onChange(satisfactionMap[newValue])
  }

  const handleClosePopup = () => {
    if (isSuccessBox && onRatingSuccess) {
      onRatingSuccess()
    }
    form.reset()
    setOpenModal(false)
    setShowConfetti(false)
  }

  const onDialogClose = () => (event: MouseEvent, reason: CancelModalReason) => {
    if (reason === CancelModalReason.BACKDROP_CLICK) {
      return
    }
    setShowConfetti(false)
    handleClosePopup()
  }

  const handleMessageChange = (onChange: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.trimStart())
  }

  const formatMessage = (values: RateConsultationDataType) => {
    const { satisfaction, selectedTags, message } = values
    if (!satisfaction || !selectedTags.length) return message

    const selectedTagsLabels = selectedTags
      .map(tag => consultationLang.getLabel(tag))
      .join(", ")

    const formattedTags = `${selectedTagsLabels}. `
    return message ? `${formattedTags}\n\n${message}` : formattedTags
  }

  const onSubmit = form.handleSubmit(async (values) => {
    setLoading(true)

    try {
      await api.request({
        ...ratingRouteConfig,
        data: {
          ...values,
          message: formatMessage(values)
        }
      })

      setIsSuccessBox(true)
      if (values.satisfaction === Satisfaction.VERY_HIGH) {
        setShowConfetti(true)
      }
    } catch (e) {
      redirectToError500Page(e)
      if (e.response?.data?.errors) {
        for (const [fieldName, fieldValue] of Object.entries(e.response.data.errors)) {
          const errorMessage = (fieldValue as string[]).join("\u000A")
          form.setError(
            fieldName as Path<RateConsultationDataType>,
            {
              type: "manual",
              message: consultationLang.getLabel(`errors:${errorMessage}`)
            }
          )

          if (fieldName === "consultation_id") {
            setGlobalError(consultationLang.getLabel(`errors:${errorMessage}`))
          }
        }
      } else {
        console.error(e.response.data)
      }
    }

    setLoading(false)
  })

  const handleGoogleReviewClick = () => {
    window.open("https://www.google.com/search?q=telemedico", "_blank")
  }

  useEffect(() => {
    if (!rate) {
      setOpenModal(true)
    }
  }, [])

  return (
    <>
      <Confetti isActive={showConfetti} />
      <Dialog
        open={openModal}
        maxWidth={"md"}
        classes={{
          paper: classes.popup
        }}
        onClose={onDialogClose}
        fullScreen={!isSmUp}
      >
        {!hideCloseButtons && <CloseButton onClick={handleClosePopup}/>}

        <DialogTitle>
          <Box mr={4} mb={1}>
            <Typography variant={"h3"}>
              {title || consultationLang.getLabel("chatConsultation:rating:consultationEnded")}
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent>
          { !isSuccessBox
            ? (
              <Box>
                <Box textAlign="center">
                  { globalError && (
                    <GlobalFormErrorMessage
                      message={globalError}
                    />
                  )}

                  <Box mt={2} mb={1}>
                    <Typography variant={"h6"} component="span">
                      {consultationLang.getLabel("chatConsultation:rating:doctorAskingForRating", { doctorName: doctorName })}
                    </Typography>
                  </Box>
                </Box>

                <form
                  autoComplete="off"
                  noValidate
                  className={classes.form}
                  onSubmit={onSubmit}
                >
                  <Controller
                    name="satisfaction"
                    control={form.control}
                    render={({ field: { onChange, value }}) => (
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Rating
                          name="satisfaction"
                          value={value}
                          size="large"
                          disabled={loading}
                          onChange={handleSatisfactionChange(onChange)}
                        />
                        {value && (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginTop: 8 }}
                          >
                            {value === Satisfaction.VERY_LOW && consultationLang.getLabel("chatConsultation:rating:satisfaction:very_low")}
                            {value === Satisfaction.LOW && consultationLang.getLabel("chatConsultation:rating:satisfaction:low")}
                            {value === Satisfaction.MEDIUM && consultationLang.getLabel("chatConsultation:rating:satisfaction:medium")}
                            {value === Satisfaction.HIGH && consultationLang.getLabel("chatConsultation:rating:satisfaction:high")}
                            {value === Satisfaction.VERY_HIGH && consultationLang.getLabel("chatConsultation:rating:satisfaction:very_high")}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />

                  <Controller
                    name="selectedTags"
                    control={form.control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FeedbackTags
                        rating={form.watch("satisfaction")}
                        selectedTags={value}
                        onTagsChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Box mt={4}>
                    <Typography variant={"h5"}>
                      {consultationLang.getLabel("chatConsultation:rating:doYouWantToSayMore")}
                    </Typography>
                  </Box>

                  <Controller
                    name="message"
                    control={form.control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        value={value}
                        label={consultationLang.getLabel("chatConsultation:rating:additionalMessage")}
                        placeholder={consultationLang.getLabel("chatConsultation:rating:additionalMessage")}
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={5}
                        onChange={handleMessageChange(onChange)}
                      />
                    )}
                  />

                  <Button
                    disabled={loading}
                    variant={"contained"}
                    color={"primary"}
                    type="submit"
                    className={classes.submitButton}
                    endIcon={ loading && <ButtonLoader/>}
                  >
                    { consultationLang.getLabel("send") }
                  </Button>
                </form>
              </Box>
            )
            : (
              <Box className={classes.succesBox}>
                <Box mb={3}>
                  <Typography
                    variant={"h6"}
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: form.watch("satisfaction") === Satisfaction.VERY_HIGH
                        ? consultationLang.getLabel("chatConsultation:rating:veryHighThanksTitle")
                        : consultationLang.getLabel("chatConsultation:rating:thanksTitle")
                    }}
                  />
                </Box>

                <Box mb={3} textAlign={"center"}>
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{
                      __html: form.watch("satisfaction") === Satisfaction.VERY_HIGH
                        ? consultationLang.getLabel("chatConsultation:rating:veryHighThanks", { doctorName: doctorName })
                        : consultationLang.getLabel("chatConsultation:rating:thanks", { doctorName: doctorName })
                    }}
                  />
                </Box>

                {form.watch("satisfaction") === Satisfaction.VERY_HIGH && (
                  <Box mb={3} p={2} borderRadius={1}>
                    <Typography variant="body1" gutterBottom>
                      {consultationLang.getLabel("chatConsultation:rating:googleMessage")}
                    </Typography>
                    <a
                      href="https://www.google.pl/maps/place/Telemedi/@52.2054088,20.9938212,17z/data=!4m8!3m7!1s0x471ecce72c6dd105:0xf341907bda0c0d97!8m2!3d52.2054055!4d20.9964015!9m1!1b1!16s%2Fg%2F11bw3dl2kv?entry=ttu&g_ep=EgoyMDI1MDMxMC4wIKXMDSoASAFQAw%3D%3D"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "block",
                        width: "100%",
                        textDecoration: "none"
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        {consultationLang.getLabel("chatConsultation:rating:googleButton")}
                      </Button>
                    </a>
                  </Box>
                )}
              </Box>
            )
          }
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ConsultationRatingModal
