import React, { FC } from "react"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core"

import useConsultationLang from "../../../hooks/useConsultationLang"
import CloseButton from "../../common/closeButton/CloseButton.component"
import { useConsultationRatingModalStyles } from "./ConsultationRatingModal.styles"

interface ConsultationRatingMessageProps {
  message: string;
}

const ConsultationRatingMessage: FC<ConsultationRatingMessageProps> = ({
  message
}) => {
  const classes = useConsultationRatingModalStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const consultationLang = useConsultationLang()

  return (
    <Dialog
      open={true}
      maxWidth={"md"}
      classes={{
        paper: classes.popup
      }}
      fullScreen={!isSmUp}
    >

      <DialogTitle>
        <div className={classes.titleWrapper}>
          <Typography variant={"h3"}>
            {consultationLang.getLabel("chatConsultation:consultation")}
          </Typography>
        </div>
      </DialogTitle>

      <DialogContent>
        <div className={classes.messageWrapper}>
          <Typography variant="h6" align="center">
            {message}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConsultationRatingMessage
