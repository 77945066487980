export const NEGATIVE_FEEDBACK_TAGS = [
  "chatConsultation:rating:tags:longWaitingTime",
  "chatConsultation:rating:tags:unclearInformation",
  "chatConsultation:rating:tags:noEmpathy",
  "chatConsultation:rating:tags:highPrice",
  "chatConsultation:rating:tags:noHealthImprovement",
  "chatConsultation:rating:tags:visitRush",
  "chatConsultation:rating:tags:contactDifficulty",
  "chatConsultation:rating:tags:unpunctual",
  "chatConsultation:rating:tags:outdatedEquipment"
]

export const POSITIVE_FEEDBACK_TAGS = [
  "chatConsultation:rating:tags:professionalism",
  "chatConsultation:rating:tags:kindness",
  "chatConsultation:rating:tags:clearExplanations",
  "chatConsultation:rating:tags:quickHelp",
  "chatConsultation:rating:tags:punctuality",
  "chatConsultation:rating:tags:competence",
  "chatConsultation:rating:tags:empathy",
  "chatConsultation:rating:tags:modernEquipment",
  "chatConsultation:rating:tags:clearInformation"
]
